const ALBUMSDATA = [
    {
        id: 0,
        title: 'Mezzanine',
        artist: 'Massive Attack',
        year: 1998
    },
    {
        id: 1,
        title: 'The Ruminant Band',
        artist: 'Fruit Bats',
        year: 2009
    }
];

export const getAlbumsData = () => ALBUMSDATA;
