import React from 'react'
import styled from 'styled-components'
 
const StyledTraining = styled.div`
 section {
    min-height: 67vh;
 }
@media screen and (min-width: 405px){ 
    section {
    min-height: 100vh;
    background-size: contain;
    background-color: #efefef;
 }
}
`;
 
const Training = () => {
    return (
        <StyledTraining>


<section
style={{backgroundImage:'url(https://lh3.googleusercontent.com/LYVEuJQhQQjANjHzlzGxbgb-m39uKT7Ncdoh49urUaXHQaLGcF5anLIkI57F_wMkyn6DDfUgInH4Vbs-REEhmVOkpzkb3NvXuR2e_HRC=s599)'}}
  className="relative bg-cover bg-center bg-no-repeat"
>
  <div
    className="absolute inset-0 bg-white/75 sm:bg-transparent sm:from-white/95 sm:to-white/25 ltr:sm:bg-gradient-to-r rtl:sm:bg-gradient-to-l"
  ></div>

  <div
    className="relative mx-auto max-w-screen-xl px-4 py-32 sm:px-6 lg:flex lg:h-screen lg:items-center lg:px-8"
  >
    <div className="max-w-xl text-center ltr:sm:text-left rtl:sm:text-right">
      <h1 className="text-3xl font-extrabold sm:text-5xl sr-only" >
        Denard Bros Pro Runs

        <strong className="block font-extrabold text-rose-700 sr-only">
          June 23rd
        </strong>
      </h1>
<div className="spacer" style={{width:'10px',height:'20vh'}}></div>
     

      <div className="mt-8 flex flex-wrap gap-4 text-center">
   

        <a style={{color:'#e0b646'}}
          href="https://www.eventbrite.com/e/denard-bros-pro-runs-the-warm-up-fashion-night-tickets-636145427097"
          className="block w-full rounded bg-black px-12 py-3 text-sm font-medium  shadow hover:text-blue-700 focus:outline-none focus:ring active:text-blue-500 sm:w-auto"
        >
          Get Tickets Now
        </a>
      </div>
    </div>
  </div>
</section>
        </StyledTraining>
    )
}
 
export default Training
 