import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom';
 
const StyledSport = styled.div`
 @import url("https://fonts.googleapis.com/css?family=Montserrat:700|Source+Sans+Pro:400,600,700&display=swap");
@font-face {
  font-family: "Bullpen Heavy Italic";
  src: url("//s3-us-west-2.amazonaws.com/s.cdpn.io/97621/bullpenhv-italic-webfont.woff2") format("woff2"), url("bullpenhv-italic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Bullpen 3D";
  src: url("//s3-us-west-2.amazonaws.com/s.cdpn.io/97621/bullpenthreed-regular-webfont.woff2") format("woff2"), url("bullpenthreed-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Bullpen Heavy";
  src: url("//s3-us-west-2.amazonaws.com/s.cdpn.io/97621/bullpenhv-regular-webfont.woff2") format("woff2"), url("bullpenhv-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
.bg-yellow {
  background: #f7be00;
}

.bg-black {
  background: #000000;
}

.font-Bullpen3D {
  font-family: "Bullpen 3D", "Garamond", "Cambria", Georgia, serif;
  font-display: swap;
}

@media (max-width: 999px) {
  .hidden-small {
    display: none !important;
  }
}

@media (min-width: 62.5em) {
  .hidden-large {
    display: none !important;
  }
}

.counter-item::before {
  background: #f7be00;
  border-radius: 50%;
  color: #000000;
  content: counter(counter-items);
  display: flex;
  font-family: "Source Sans Pro", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 1.875em;
  font-weight: 700;
  align-items: center;
  justify-content: center;
  height: 2em;
  width: 2em;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 1.5rem;
  padding: 0.5em;
  text-align: center;
}
.counter-item-one::before {
  content: "1";
}
.counter-item-two::before {
  content: "2";
}
.counter-item-three::before {
  content: "3";
}

.has-tooltip {
  position: relative;
}
.has-tooltip:hover .tooltip, .has-tooltip:focus .tooltip,
.has-tooltip a:focus .tooltip, .has-tooltip:hover .icon-tooltip, .has-tooltip:focus .icon-tooltip,
.has-tooltip a:focus .icon-tooltip {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.tooltip {
  background-color: #f7be00;
  color: #000000;
  font-size: 0.875rem;
  line-height: 1.2;
  margin-bottom: 0.875rem;
  opacity: 0;
  padding: 0.5rem 0.45em 0.65rem;
  position: absolute;
  bottom: 100%;
  left: -100%;
  left: -50%;
  right: -50%;
  visibility: hidden;
  text-align: center;
  transform: translateY(10px);
  transition: all 0.25s ease-out;
  border-radius: 0.2rem;
  z-index: 50;
}
.tooltip::before {
  content: "";
  display: inline-block;
  position: absolute;
  border: 8px solid transparent;
  border-top-color: #f7be00;
  bottom: -16px;
  left: calc(50% - 8px);
}
.tooltip.bottom {
  bottom: 0;
  top: 100%;
}
.tooltip.bottom::before {
  bottom: 0;
  top: -16px;
}
.tooltip.flush-r {
  right: -0.5rem;
  left: auto;
}
.tooltip.flush-r::before {
  right: 0.75rem;
  left: auto;
}
.tooltip.flush-l {
  left: -0.5rem;
}
.tooltip.flush-l::before {
  left: 0.65rem;
}

.theme-light {
  background-color: #f8dc32;
  color: #000000;
}

.theme-dark {
  background-color: #000000;
  color: #ffffff;
}

.is-visible .icon-menu {
  display: none;
}
.is-visible .icon-close {
  display: inline-block;
}
.is-visible #section-menu {
  height: 7rem;
}
.is-visible #section-menu li > *.active {
  padding: 1em 2rem;
}

html {
  background: #636569;
  font-size: 100%;
}

body {
  background: #f7be00;
  box-shadow: 0 0 4rem 0 rgba(34, 34, 34, 0.74);
  font-family: "Source Sans Pro", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 16px;
  position: relative;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-family: "Bullpen Heavy", "Garamond", "Cambria", Georgia, serif;
  margin-top: 0;
  margin-bottom: 1.5rem;
}

.btn-primary,
a.btn-primary {
  border-radius: 0;
  border-style: solid;
  border-width: 0.25em;
  display: inline-block;
  font-family: "Montserrat", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-display: swap;
  font-weight: 700;
  font-size: 0.875em;
  letter-spacing: 0.07em;
  margin-right: auto;
  margin-left: auto;
  padding: 0.938em 2.125em;
  text-align: center;
  text-transform: uppercase;
  transition: 0.4s ease;
  background: #f7be00;
  border-color: #f7be00;
  color: #000000;
}
.btn-primary:hover, .btn-primary:focus,
a.btn-primary:hover,
a.btn-primary:focus {
  text-decoration: none;
}
.btn-primary:hover, .btn-primary:focus,
a.btn-primary:hover,
a.btn-primary:focus {
  background: #000000;
  border-color: #000000;
  color: #f7be00;
}
.btn-primary-dark,
a.btn-primary-dark {
  border-radius: 0;
  border-style: solid;
  border-width: 0.25em;
  display: inline-block;
  font-family: "Montserrat", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-display: swap;
  font-weight: 700;
  font-size: 0.875em;
  letter-spacing: 0.07em;
  margin-right: auto;
  margin-left: auto;
  padding: 0.938em 2.125em;
  text-align: center;
  text-transform: uppercase;
  transition: 0.4s ease;
  background: #000000;
  border-color: #000000;
  color: #f7be00;
}
.btn-primary-dark:hover, .btn-primary-dark:focus,
a.btn-primary-dark:hover,
a.btn-primary-dark:focus {
  text-decoration: none;
}
.btn-primary-dark:hover, .btn-primary-dark:focus,
a.btn-primary-dark:hover,
a.btn-primary-dark:focus {
  background: #f7be00;
  border: 0.25em solid #f7be00;
  color: #000000;
}
.btn-primary-outline,
a.btn-primary-outline {
  border-radius: 0;
  border-style: solid;
  border-width: 0.25em;
  display: inline-block;
  font-family: "Montserrat", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-display: swap;
  font-weight: 700;
  font-size: 0.875em;
  letter-spacing: 0.07em;
  margin-right: auto;
  margin-left: auto;
  padding: 0.938em 2.125em;
  text-align: center;
  text-transform: uppercase;
  transition: 0.4s ease;
  background: #f7be00;
  border-color: #f7be00;
  color: #000000;
  border: 0.25em solid #000000;
}
.btn-primary-outline:hover, .btn-primary-outline:focus,
a.btn-primary-outline:hover,
a.btn-primary-outline:focus {
  text-decoration: none;
}
.btn-primary-outline:hover, .btn-primary-outline:focus,
a.btn-primary-outline:hover,
a.btn-primary-outline:focus {
  background: #000000;
  border-color: #000000;
  color: #f7be00;
}
.btn-primary-outline-dark,
a.btn-primary-outline-dark {
  border-radius: 0;
  border-style: solid;
  border-width: 0.25em;
  display: inline-block;
  font-family: "Montserrat", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-display: swap;
  font-weight: 700;
  font-size: 0.875em;
  letter-spacing: 0.07em;
  margin-right: auto;
  margin-left: auto;
  padding: 0.938em 2.125em;
  text-align: center;
  text-transform: uppercase;
  transition: 0.4s ease;
  background: #000000;
  border-color: #000000;
  color: #f7be00;
  border: 0.25em solid #f7be00;
}
.btn-primary-outline-dark:hover, .btn-primary-outline-dark:focus,
a.btn-primary-outline-dark:hover,
a.btn-primary-outline-dark:focus {
  text-decoration: none;
}
.btn-primary-outline-dark:hover, .btn-primary-outline-dark:focus,
a.btn-primary-outline-dark:hover,
a.btn-primary-outline-dark:focus {
  background: #f7be00;
  border: 0.25em solid #f7be00;
  color: #000000;
}
.btn-outline-light,
a.btn-outline-light {
  border-radius: 0;
  border-style: solid;
  border-width: 0.25em;
  display: inline-block;
  font-family: "Montserrat", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-display: swap;
  font-weight: 700;
  font-size: 0.875em;
  letter-spacing: 0.07em;
  margin-right: auto;
  margin-left: auto;
  padding: 0.938em 2.125em;
  text-align: center;
  text-transform: uppercase;
  transition: 0.4s ease;
  background: #f7be00;
  border-color: #f7be00;
  color: #000000;
  background: transparent;
  border: 0.25em solid #f7be00;
  color: #f7be00;
}
.btn-outline-light:hover, .btn-outline-light:focus,
a.btn-outline-light:hover,
a.btn-outline-light:focus {
  text-decoration: none;
}
.btn-outline-light:hover, .btn-outline-light:focus,
a.btn-outline-light:hover,
a.btn-outline-light:focus {
  background: #000000;
  border-color: #000000;
  color: #f7be00;
}
.btn-outline-light:hover, .btn-outline-light:focus,
a.btn-outline-light:hover,
a.btn-outline-light:focus {
  background: #f7be00;
  border: 0.25em solid #f7be00;
  color: #000000;
}
.btn-outline-dark,
a.btn-outline-dark {
  border-radius: 0;
  border-style: solid;
  border-width: 0.25em;
  display: inline-block;
  font-family: "Montserrat", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-display: swap;
  font-weight: 700;
  font-size: 0.875em;
  letter-spacing: 0.07em;
  margin-right: auto;
  margin-left: auto;
  padding: 0.938em 2.125em;
  text-align: center;
  text-transform: uppercase;
  transition: 0.4s ease;
  background: #f7be00;
  border-color: #f7be00;
  color: #000000;
  background: transparent;
  border: 0.25em solid #000000;
}
.btn-outline-dark:hover, .btn-outline-dark:focus,
a.btn-outline-dark:hover,
a.btn-outline-dark:focus {
  text-decoration: none;
}
.btn-outline-dark:hover, .btn-outline-dark:focus,
a.btn-outline-dark:hover,
a.btn-outline-dark:focus {
  background: #000000;
  border-color: #000000;
  color: #f7be00;
}

p {
  font-size: 1.2em;
  line-height: 1.7;
  margin-bottom: 2rem;
  max-width: 60ch;
}
p strong {
  font-weight: 600;
}

.icon {
  display: inline-block;
  fill: currentColor;
  height: 1em;
  width: 1em;
  vertical-align: middle;
  position: relative;
  top: -0.0625em;
  margin-right: 0.25rem;
  margin-left: 0.25rem;
  text-align: center;
  stroke-width: 0;
}

img {
  max-width: 100%;
}

ul {
  list-style-type: none;
}
ul a {
  text-decoration: none;
}

.container {
  max-width: 60em;
  padding-top: 2em;
  padding-right: 2em;
  padding-bottom: 2em;
  padding-left: 2em;
  width: 100%;
}

section > .container {
  padding-top: 4em;
  padding-right: 2em;
  padding-bottom: 4em;
  padding-left: 2em;
}

.container-fluid > .navbar-collapse {
  margin-right: 0;
  margin-left: 0;
}
@media (min-width: 48em) {
  .container-fluid > .navbar-header {
    margin-right: -15px;
  }
}

.navbar {
  border: 0;
  flex-grow: 1;
  flex-basis: 100%;
  margin-bottom: 2rem;
  min-height: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 3;
}

.navbar-nav {
  --main-menu-font-size: 2.5em;
  margin: 0;
}
.navbar-nav > li > a.btn-outline-light:hover, .navbar-nav > li > a.btn-outline-light:focus {
  background: #f7be00;
  color: #000000;
}
.navbar-nav > li:not(:last-child) {
  margin-bottom: 3em;
}
.navbar-nav > li:not(.cta) > a {
  color: #f7be00;
  font-family: "Bullpen Heavy", "Garamond", "Cambria", Georgia, serif;
  font-size: var(--main-menu-font-size);
  padding: 0;
  text-transform: uppercase;
  transition: font-size 0.4s ease, color 0.4s ease;
}
.navbar-nav > li:not(.cta) > a:hover, .navbar-nav > li:not(.cta) > a:focus {
  background: transparent;
  color: #ffffff;
  font-size: calc(var(--main-menu-font-size) - 0.57rem);
}
.navbar-nav > li.cta {
  font-size: 1.125em;
}
.navbar-nav > li.cta > a {
  display: inline-block;
  padding: 1em;
}
@media (min-width: 48em) {
  .navbar-nav > li {
    float: none;
  }
}
@media (min-width: 62.5em) {
  .navbar-nav {
    --main-menu-font-size: 4.5em;
  }
}

.navbar-header {
  display: flex;
  float: none;
  justify-content: space-between;
}

.navbar-brand {
  float: none;
  height: auto;
  padding: 0;
}
.navbar-brand svg,
.navbar-brand svg > * {
  transition: 0.4s ease;
}

.logo-icon {
  height: 6em;
}

.logo-full-ribbon {
  display: none;
}

.navbar-controls {
  display: flex;
  align-items: flex-start;
  margin-left: auto;
}

.navbar-toggle {
  display: inline-block;
  border: 0;
  border-radius: 0;
  float: none;
  margin: 0;
  padding: 0.5em;
  transition: 0.4s ease;
}
.navbar-toggle-login {
  margin-top: 0.27em;
  margin-right: 0;
  min-width: 1.875em;
  opacity: 0.6;
  padding: 0.1em;
}
.navbar-toggle-login:hover, .navbar-toggle-login:focus {
  opacity: 1;
}
.navbar-toggle-menu {
  background: #000000;
  color: #ffffff;
  line-height: 1;
  padding: 0.875em 0.875em 1em 2em;
  clip-path: polygon(100% 0, 100% 50%, 100% 100%, 0% 100%, 25% 50%, 0% 0%);
  -webkit-clip-path: polygon(100% 0, 100% 50%, 100% 100%, 0% 100%, 25% 50%, 0% 0%);
}
.navbar-toggle span {
  background: currentColor;
}

.icon-bar {
  transform-origin: center;
  transition: opacity 0.4s ease, transform 0.4s ease;
}

.logo-icon-sm {
  margin-bottom: 1rem;
  width: 4.25rem;
}

.icon-list {
  display: flex;
  font-size: 1.5em;
  justify-content: space-evenly;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2rem;
  padding-top: 1.5em;
  padding-bottom: 0;
  padding-left: 0;
}
.icon-list > li {
  margin: 0 0.3rem;
}

.site-header {
  background: #f7be00;
  background-attachment: fixed;
  background: linear-gradient(45deg, #f7be00 0%, rgba(209, 118, 0, 0.15) 70%), url("//s3-us-west-2.amazonaws.com/s.cdpn.io/97621/bg-line-fractal-orange.svg") no-repeat right -10vh, #f7be00;
  background-blend-mode: multiply;
  display: flex;
  flex-direction: column;
}

.main-content {
  position: relative;
  z-index: 1;
}

.hero {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  position: relative;
  z-index: 1;
}
.hero .text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 11em;
  padding-right: 2em;
  padding-bottom: 3em;
  padding-left: 2em;
}
.hero .media {
  display: flex;
  flex: 1 1 60vh;
  margin-top: 0;
}
.hero .title {
  flex-basis: 100%;
  font-size: 3em;
  line-height: 1.2;
  margin-bottom: 1.5rem;
  max-width: 14ch;
  text-transform: uppercase;
}
.hero .subtitle {
  max-width: 68ch;
}
.hero-img {
    margin-top: 3em;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: top;
     object-position: top;
  width: 100vw;
  clip-path: polygon(0 100%, 0 18%, 43% 6%, 100% 25%, 100% 100%);
  -webkit-clip-path: polygon(0 100%, 0 18%, 43% 6%, 100% 25%, 100% 100%);
}
.hero-img.img-mobile {
  display: none;
}
.hero .actions a {
  border-radius: 0;
  border-style: solid;
  border-width: 0.25em;
  display: inline-block;
  font-family: "Montserrat", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-display: swap;
  font-weight: 700;
  font-size: 0.875em;
  letter-spacing: 0.07em;
  margin-right: auto;
  margin-left: auto;
  padding: 0.938em 2.125em;
  text-align: center;
  text-transform: uppercase;
  transition: 0.4s ease;
  background: #000000;
  border-color: #000000;
  color: #f7be00;
}
.hero .actions a:hover, .hero .actions a:focus {
  text-decoration: none;
}
.hero .actions a:hover, .hero .actions a:focus {
  background: #f7be00;
  border: 0.25em solid #f7be00;
  color: #000000;
}
@media (min-width: 62.5em) {
  .hero .actions a {
    border-radius: 0;
    border-style: solid;
    border-width: 0.25em;
    display: inline-block;
    font-family: "Montserrat", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    font-display: swap;
    font-weight: 700;
    font-size: 0.875em;
    letter-spacing: 0.07em;
    margin-right: auto;
    margin-left: auto;
    padding: 0.938em 2.125em;
    text-align: center;
    text-transform: uppercase;
    transition: 0.4s ease;
    background: #f7be00;
    border-color: #f7be00;
    color: #000000;
    background: transparent;
    border: 0.25em solid #f7be00;
    color: #f7be00;
  }
  .hero .actions a:hover, .hero .actions a:focus {
    text-decoration: none;
  }
  .hero .actions a:hover, .hero .actions a:focus {
    background: #000000;
    border-color: #000000;
    color: #f7be00;
  }
  .hero .actions a:hover, .hero .actions a:focus {
    background: #f7be00;
    border: 0.25em solid #f7be00;
    color: #000000;
  }
}

.info {
  border-top: 2px solid #fff;
}
.info header {
  background: #ffffff;
  -webkit-clip-path: polygon(0 33%, 0 0, 50% 0, 100% 0, 100% 33%, 50% 100%);
  clip-path: polygon(0 33%, 0 0, 50% 0, 100% 0, 100% 33%, 50% 100%);
  margin-top: -1px;
  padding-top: 2.25em;
  padding-right: 2em;
  padding-bottom: 2em;
  padding-left: 2em;
  position: relative;
  text-align: center;
}
.info header svg {
  max-width: 6em;
}

@supports (background-blend-mode: color-burn) {
  .info {
    background-image: url("https://dl3.pushbulletusercontent.com/nTZpPcaXBtGR5TkIEuwCkfiliEhqjLhN/auberywkidbball.png"), linear-gradient(160deg, #138687 0%, #636569 100%);
    background-blend-mode: color-burn;
    background-repeat: no-repeat, repeat;
    background-position: center top, center center;
    background-size: cover;
  }
  @media screen and (min-width: 40em) {
    .info {
      background-position: center -36vh, center center;
    }
  }
}
.steps {
  color: #ffffff;
  display: flex;
  flex-wrap: wrap;
  padding-top: 4em;
  padding-right: 1em;
  padding-bottom: 4em;
  padding-left: 1em;
  position: relative;
  z-index: 0;
}
.steps .actions {
  padding-top: 2em;
  padding-bottom: 2em;
}
@media screen and (max-width: 40em) {
  .steps {
    align-items: center;
    flex-direction: column;
  }
}
@media screen and (min-width: 40em) {
  .steps {
    padding-top: 12vw;
    padding-bottom: 12vw;
  }
  .steps .actions {
    display: flex;
    justify-content: center;
    flex-basis: 100%;
    padding-top: 3em;
    padding-bottom: 0;
  }
}

.step {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 1em;
  padding-right: 2em;
  padding-bottom: 1em;
  padding-left: 2em;
}
.step h2 {
  font-family: "Source Sans Pro", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 1.5em;
  font-weight: 700;
  max-width: 20ch;
  text-align: center;
}
@media screen and (min-width: 40em) {
  .step {
    flex-grow: 1;
  }
}

.callout {
  background: #ffffff;
  text-align: center;
}
.callout h2 {
  margin-bottom: 1rem;
}
.callout .logo {
  display: block;
  margin-right: auto;
  margin-left: auto;
  max-width: 10rem;
}
.callout p {
  font-size: 1.4em;
  margin-right: auto;
  margin-left: auto;
  max-width: 46ch;
}

.join-beta {
  background: linear-gradient(to top, #f7be00 0%, rgba(209, 118, 0, 0.15) 70%), url("https://dl3.pushbulletusercontent.com/E6ATUjGF5GIxWyMK9bb0fyYLyjHPcckd/prorunburnbg.png") no-repeat right 12%, #fff023;
  background-size: 100%;
  padding-top: 4rem;
  padding-bottom: 1rem;
}
.join-beta h2 {
  font-size: 2.35em;
  max-width: 13ch;
}
/* 
.site-footer {
  background: url("//s3-us-west-2.amazonaws.com/s.cdpn.io/97621/bg-line-fractal-white-alpha-25.svg") no-repeat right bottom, #000000;
  background-attachment: fixed;
  background-size: 100%;
  background-blend-mode: luminosity;
  color: #ffffff;
  -webkit-clip-path: polygon(0 100%, 0 20%, 50% 0, 100% 20%, 100% 100%);
  clip-path: polygon(0 100%, 0 20%, 50% 0, 100% 20%, 100% 100%);
}
.site-footer .container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 7em;
  padding-bottom: 4em;
}
.site-footer p {
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 2rem;
  max-width: 34ch;
  text-align: center;
}
.site-footer .copyrights {
  color: #bebebe;
  font-size: 90%;
  font-weight: 400;
  margin-bottom: 0;
}
.site-footer .copyrights a {
  color: currentColor;
  text-decoration: underline;
  -webkit-text-decoration-skip: ink;
          text-decoration-skip-ink: auto;
} */

.navbar-collapse {
  background: url("//s3-us-west-2.amazonaws.com/s.cdpn.io/97621/bg-line-fractal-white-alpha-25.svg") no-repeat right bottom, #000000;
  background-attachment: fixed;
  background-size: 100%;
  background-blend-mode: luminosity;
  color: #ffffff;
  display: flex !important;
  align-items: center;
  margin: 0;
  padding-right: 2em;
  padding-left: 2em;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transition: opacity 0.4s ease;
  z-index: 0;
}
.navbar-collapse.collapse, .navbar-collapse.collapsing {
  display: flex !important;
  height: 100vh !important;
}
.navbar-collapse.collapsing {
  pointer-events: none;
}
.navbar-collapse.collapsing, .navbar-collapse.in {
  z-index: 2;
}
.navbar-collapse.in {
  position: static;
}
@media (min-width: 62.5em) {
  .navbar-collapse ul {
    padding-left: 4em;
  }
}

.main-menu-visible .navbar {
  margin-bottom: 0;
}
.main-menu-visible .navbar-collapse {
  display: flex !important;
  min-height: 100vh;
  height: 100vh !important;
}
.main-menu-visible .navbar-collapse,
.main-menu-visible .navbar-collapse.in {
  opacity: 1;
  pointer-events: auto;
}
.main-menu-visible .logo-icon {
  height: 4em;
}
.main-menu-visible .logo-full-ribbon {
  height: 8em;
}
.main-menu-visible .logo-icon .ribbon,
.main-menu-visible .logo-full-ribbon .ribbon {
  fill: #f7be00;
}
.main-menu-visible .logo-icon .text,
.main-menu-visible .logo-icon .crown,
.main-menu-visible .logo-icon .letter,
.main-menu-visible .logo-full-ribbon .text,
.main-menu-visible .logo-full-ribbon .crown,
.main-menu-visible .logo-full-ribbon .letter {
  fill: #000000;
}
.main-menu-visible .navbar-toggle-login {
  fill: #ffffff;
  opacity: 0;
}
.main-menu-visible .navbar-toggle-menu {
  background: #f7be00;
  color: #000000;
}
.main-menu-visible .icon-bar.top {
  transform: translateY(6px) rotate(-45deg);
}
.main-menu-visible .icon-bar.mid {
  opacity: 0;
}
.main-menu-visible .icon-bar.bottom {
  transform: translateY(-6px) rotate(45deg);
}

.navbar-desktop {
  --main-menu-font-size: 1.25em;
  display: none;
}
.navbar-desktop .nav {
  display: flex;
  align-items: center;
  margin-left: 0;
  padding-left: 0;
}
.navbar-desktop .nav > li > a {
  color: var(--color-brand-dark);
  font-family: "Bullpen Heavy", "Garamond", "Cambria", Georgia, serif;
  font-size: var(--main-menu-font-size);
  line-height: 1;
  padding: 0.25em 0.5em 0.15em;
  text-transform: uppercase;
  transition: 0.4s ease;
}
.navbar-desktop .nav > li > a:hover, .navbar-desktop .nav > li > a:focus {
  background: #f7be00;
  color: #000000;
}

@media (max-width: 999px) {
  .footer-desktop {
    display: none !important;
  }
}

@media (min-width: 48em) {
  .navbar > .container-fluid .navbar-brand {
    margin-left: 4vw;
  }

  .navbar-nav {
    --main-menu-font-size: 3.5em;
    margin-left: 7vw;
  }
  .navbar-nav > li:not(:last-child) {
    margin-bottom: 4em;
  }

  .hero-img {
    clip-path: polygon(0 100%, 0 23%, 43% 0, 100% 39%, 100% 100%);
    -webkit-clip-path: polygon(0 100%, 0 23%, 43% 0, 100% 39%, 100% 100%);
  }
  .hero .text {
    padding-top: 16em;
    padding-left: 10vw;
    padding-bottom: 10vw;
  }
  .hero .title {
    font-size: 4em;
  }

  .navbar-brand .logo-icon {
    display: none;
  }

  .logo-full-ribbon {
    display: block;
    height: 10em;
  }
}
@media (max-width: 767px) {
  .hero .subtitle {
    display: none;
  }
}
@media (max-width: 999px) {
  .hero .title {
    color: #000000;
  }
}
@media (min-width: 62.5em) {
  body {
    background: #000000;
  }

  .site-header {
    background: transparent;
    color: #f7be00;
    overflow: hidden;
  }

  .hero {
    height: 100vh;
  }
  .hero .text {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex: 1 1 60vh;
    padding-top: 16vw;
    padding-bottom: 0;
    padding-right: 2em;
    position: relative;
    z-index: 1;
  }
  .hero .text-wrap {
    padding-right: 5em;
  }
  .hero .title {
    font-size: 4.5em;
    flex-basis: auto;
    margin-bottom: 1rem;
  }
  .hero .subtitle {
    color: #eee;
    font-size: 1.35em;
    margin-bottom: 2.5em;
  }
  .hero .actions {
    margin-bottom: 0;
  }
  .hero .media {
    background: url("//s3-us-west-2.amazonaws.com/s.cdpn.io/97621/bg-line-fractal-white-alpha-25.svg") no-repeat right bottom, linear-gradient(45deg, #5d353d, #2c2150);
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.75;
    height: 100vh;
    width: 100vw;
    z-index: 0;
  }
  .hero-img {
    -webkit-clip-path: unset;
    clip-path: unset;
    mix-blend-mode: luminosity;
    opacity: 0.4;
  }

  .footer-desktop {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 1em;
    padding-bottom: 1em;
    padding-left: 5vw;
    position: relative;
    z-index: 1;
  }
  .footer-desktop .copyrights {
    color: #ddd;
    font-weight: 300;
    font-size: 0.875em;
    margin: 0;
    max-width: 100%;
    opacity: 0.4;
    padding-right: 1em;
    transition: 0.4s ease;
  }
  .footer-desktop .copyrights a {
    color: currentColor;
    text-decoration: underline;
    transition: 0.4s ease;
  }
  .footer-desktop .copyrights a:hover, .footer-desktop .copyrights a:focus {
    color: #fff;
    text-decoration: none;
  }
  .footer-desktop .copyrights:hover, .footer-desktop .copyrights:focus {
    opacity: 1;
  }
  .footer-desktop .icon-list {
    justify-content: flex-end;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 0;
    padding-top: 0;
  }
  .footer-desktop .icon-fb {
    font-size: 0.875em;
  }

  .navbar {
    position: fixed;
    top: 0;
    width: 100%;
  }

  .logo-full-ribbon.light .ribbon {
    fill: #f7be00;
  }
  .logo-full-ribbon.light .text,
.logo-full-ribbon.light .crown {
    fill: #000000;
  }

  .navbar-collapse.collapsing, .navbar-collapse.in {
    display: none !important;
    z-index: 0;
  }

  .navbar-controls {
    align-items: center;
    align-self: flex-start;
    padding-top: 1em;
    padding-right: 1em;
  }

  .navbar-toggle-login {
    font-size: 1.125em;
    margin-left: 0.5rem;
  }
  .navbar-toggle-menu, .navbar-toggle-login {
    display: none;
  }
  .navbar-toggle .icon-user {
    fill: #ffffff;
    height: 1.75em;
    width: 1.75em;
  }

  .navbar-desktop {
    display: block;
  }

  .info {
    background-position: center -10vw, center center;
  }

  
.site-footer {
    display: none;
  }
}
@media (min-width: 120em) {
  body {
    font-size: 22px;
  }

  .hero .text {
    font-size: 0.875vw;
    padding-bottom: 4vh;
  }
  .hero .title {
    margin-top: 0;
  }
  .hero .subtitle {
    margin-bottom: 3vw;
  }
  .hero .btn-primary-dark:hover, .hero .btn-primary-dark:focus {
    border-color: #f7be00;
  }

  .join-beta {
    display: flex;
    align-items: center;
  }

  .navbar-brand {
    margin-left: 6em;
  }

  .logo-full-ribbon {
    display: block;
    height: 14em;
  }

  .nav-controls {
    font-size: 1.5em;
  }

  .navbar-toggle .icon-bar {
    width: 30px;
    height: 3px;
  }
  .navbar-toggle .icon-bar + .icon-bar {
    margin-top: 0.2em;
  }

  .main-menu-visible .icon-bar.bottom {
    transform: translateY(-10px) rotate(45deg);
  }
  .main-menu-visible .logo-full-ribbon {
    height: 10em;
  }
}
@media (min-width: 2400px) {
  .navbar-desktop {
    font-size: 1.4em;
  }

  .hero .text {
    padding-bottom: 0;
  }
  .hero .title {
    padding-top: 4vw;
  }
}
@media (max-height: 50em) and (min-width: 62.5em) {
  .navbar > .container-fluid .navbar-brand {
    margin-left: 2vw;
  }

  .logo-full-ribbon {
    height: 6em;
  }
}
@media screen and (min-height: 112.5em) and (min-width: 120em) {
  body {
    font-size: 22px;
  }
}
.glider,
.glider-contain {
  margin: 0 auto;
  position: relative;
}

.glider,
.glider-track {
  transform: translateZ(0);
}

.glider-dot,
.glider-next,
.glider-prev {
  border: 0;
  padding: 0;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  outline: 0;
}

.glider-contain {
  width: 100%;
}

.glider {
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
}

.glider-track {
  width: 100%;
  margin: 0;
  padding: 4em 0 7em 0;
  display: flex;
  z-index: 1;
}

.glider.draggable {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: -webkit-grab;
  cursor: grab;
}

.glider.draggable .glider-slide img {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  pointer-events: none;
}

.glider.drag {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

.glider-slide {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  justify-content: center;
  align-content: center;
  width: 100%;
  min-width: 150px;
}

.glider-slide img {
  max-width: 100%;
}

.glider::-webkit-scrollbar {
  opacity: 0;
  height: 0;
}

.glider-next,
.glider-prev {
  position: absolute;
  background: 0 0;
  z-index: 2;
  font-size: 40px;
  text-decoration: none;
  left: -23px;
  top: 30%;
  cursor: pointer;
  color: #666;
  opacity: 1;
  line-height: 1;
  transition: opacity 0.5s cubic-bezier(0.17, 0.67, 0.83, 0.67), color 0.5s cubic-bezier(0.17, 0.67, 0.83, 0.67);
}

.glider-next:focus,
.glider-next:hover,
.glider-prev:focus,
.glider-prev:hover {
  color: #ccc;
}

.glider-next {
  right: -23px;
  left: auto;
}

.glider-next.disabled,
.glider-prev.disabled {
  opacity: 0.25;
  color: #666;
  cursor: default;
}

.glider-hide {
  opacity: 0;
}

.glider-dots {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;
  padding: 0;
}

.glider-dot {
  display: block;
  cursor: pointer;
  color: #ccc;
  border-radius: 999px;
  background: #ccc;
  width: 12px;
  height: 12px;
  margin: 7px;
}

.glider-dot:focus,
.glider-dot:hover {
  background: #ddd;
}

.glider-dot.active {
  background: #a89cc8;
}

@media (max-width: 36em) {
  .glider::-webkit-scrollbar {
    opacity: 1;
    -webkit-appearance: none;
    width: 7px;
    height: 3px;
  }

  .glider::-webkit-scrollbar-thumb {
    opacity: 1;
    border-radius: 99px;
    background-color: rgba(156, 156, 156, 0.25);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.25);
  }
}
#main .glider-slide h2 {
  color: #8cc9f0;
  font-weight: bold;
}

#main .glider-slide:nth-child(3n) h1 {
  color: #efa8b0;
}

#main .glider-slide:nth-child(2n) h1 {
  color: #a89cc8;
}

.gradient-border {
  position: relative;
  /* box-shadow: 0 0 3px 0 rgba(247, 186, 233, 0.5); */
  /* border-top: 3px solid; */
  /* border-image: linear-gradient(to right, #8cc9f0, #efa8b0, #a89cc8) 5; */
}

.gradient-border-bottom {
  position: relative;
  /* box-shadow: 0 0 3px 0 rgba(247, 186, 233, 0.5); */
  /* border-bottom: 3px solid; */
  /* border-image: linear-gradient(to left, #efa8b0, #8cc9f0, #a89cc8) 5; */
}

.gradient-border:before {
  position: absolute;
  top: 0;
  content: " ";
  bottom: 0;
  left: 0;
  /* border-right: 3px solid; */
  z-index: 2;
  /* border-image: linear-gradient(to bottom, #8cc9f0, #efa8b0, #a89cc8) 5; */
}

.gradient-border:after {
  position: absolute;
  top: 0;
  content: " ";
  bottom: 0;
  right: 0;
  /* border-right: 3px solid; */
  /* border-image: linear-gradient(to bottom, #a89cc8, #8cc9f0, #efa8b0) 5; */
  z-index: 2;
}

@media (max-width: 36em) {
  .ios .gradient-border:after,
.android .gradient-border:after {
    border-right: 1px solid;
  }

  .ios .gradient-border:before,
.android .gradient-border:before {
    border-left: 1px solid;
  }
}
.glider-dots {
  margin-top: 15px;
}

.glider-contain {
  margin-bottom: 0;
}

.glider-slide {
  /* background: #F7BE04;
  border-radius: .5em; */
  box-shadow: 0 0 1em 1px rgba(0, 0, 0, 0.5);
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE10+/Edge */
  user-select: none;
  /* Standard */
}

.glider-persp .glider-slide {
  margin: 7px 0;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  transform-style: preserve-3d;
  will-change: transform;
  transform: perspective(1500px) rotateY(0deg) scale(1) translateZ(0);
  transform-origin: 0 0;
  transition: transform 600ms cubic-bezier(0.23, 1, 0.32, 1);
}

.glider-persp .glider-slide.center {
  box-shadow: 0 0 1.5em 1px rgba(0, 0, 0, 0.75);
  transform: perspective(1500px) rotateY(0deg) scale(1.2) translateZ(0);
  transform-origin: center 50%;
  /* padding: 0 1em; */
  z-index: 1;
}

/* .glider-persp .glider-slide.right-3 {
  transform: perspective(1500px) rotateY(-50deg) scale(1.35) translateZ(0);
  transform-origin: 0% 50% 0;
  margin: 10px -8px;
}

.glider-persp .glider-slide.left-3 {
  transform: perspective(1500px) rotateY(50deg) scale(1.35) translateZ(0);
  transform-origin: 0% 50% 0;
  margin: 10px 0;
}

.glider-persp .glider-slide.left-2 {
  transform: perspective(1500px) rotateY(50deg) scale(1.2) translateZ(0);
  transform-origin: 100% 50% 0;
}

.glider-persp .glider-slide.right-2 {
  transform: perspective(1500px) rotateY(-50deg) scale(1.2) translateZ(0);
  transform-origin: 0 50% 0;
}

.glider-persp .glider-slide.left-1 {
  transform: perspective(1200px) rotateY(34deg) scale(1.1) translateZ(0);
  transform-origin: 100% 50% 0;
}

.glider-persp .glider-slide.right-1 {
  transform: perspective(1200px) rotateY(-34deg) scale(1.1) translateZ(0);
  transform-origin: 0 50% 0;
} */
.glider-slide h2 {
  color: #000;
  cursor: pointer;
}

.glider-persp .glider-slide {
  margin: 10px;
}

html,
body {
  width: 100%;
  overflow-x: hidden;
}

.glider-persp {
  margin: 0 -20px;
  max-width: 120%;
}

@media (max-width: 36em) {
  .glider-persp .glider-slide {
    margin: 3px;
    min-height: 100px;
  }

  .glider-persp {
    height: 180px;
  }
}
.glider-persp .glider-track {
  position: relative;
  top: 0;
}

.glider-persp .gradient-border {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5em 1.5em;
  padding: 0;
}

.glider-persp .gradient-border,
.glider-persp .gradient-border-bottom {
  width: 100%;
  height: 100%;
  flex: 1;
}

@-moz-document url-prefix() {
  .glider-track {
    margin-bottom: 17px;
  }

  .gradient-border {
    overflow: hidden;
  }

  /* doesnt play well with auto hiding */
  #glider-persp {
    overflow: hidden;
  }
}
.card {
  background: #f7be00;
  background-attachment: fixed;
  background: linear-gradient(45deg, #f7be00 0%, rgba(209, 118, 0, 0.15) 70%), url("//s3-us-west-2.amazonaws.com/s.cdpn.io/97621/bg-line-fractal-orange.svg") no-repeat right bottom, #f7be00;
  background-blend-mode: multiply;
  border-radius: 0.5em;
  box-shadow: 0.25em 0.25em 2em 0.1em rgba(0, 0, 0, 0.35);
  color: #000000;
  position: relative;
}
.hero .card {
  max-width: 25em;
}
.card--media {
  border-radius: 0.5em 0.5em 0 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  z-index: 0;
}
.card--media > a {
  border-radius: 0.5em 0.5em 0 0;
  display: flex;
  overflow: hidden;
  transition: flex-basis 0.4s ease;
}
.card--media img {
  border-radius: 0.5em 0.5em 0 0;
  -o-object-fit: cover;
     object-fit: cover;
}
.card--content {
  border-radius: 0 0 0.5em 0.5em;
  padding: 1.5em;
}
.card--title {
  color: rgba(0, 0, 0, 0.5);
  font-family: "Source Sans Pro", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 1.25em;
  font-weight: 700;
  margin-bottom: 0.5rem;
}
.card--label {
  font-size: 1.875em;
  font-weight: 600;
  line-height: 1;
  margin-bottom: 1.5rem;
}
.card--copy {
  display: none;
  line-height: 1.4;
}
.card--actions {
  display: flex;
}
.card--actions a {
  margin-left: 0;
  padding: 0.45em 1.5em;
}
.card--actions a:hover, .card--actions a:focus {
  border-color: #000000;
}
.card .toggle--close {
  display: none;
  position: absolute;
  top: 0;
  left: 2em;
  z-index: 1;
}
@media screen and (max-width: 999px) {
  .card {
    display: none;
  }
}

.card--carousel .carousel-inner {
  display: grid;
}
.card--carousel .carousel-inner > .item {
  display: block;
  grid-column: 1/1;
  grid-row: 1/1;
  opacity: 0;
  position: relative;
  transition: opacity 2s ease-in-out;
}
.card--carousel .carousel-inner > .item.active {
  opacity: 1;
}
.card--carousel .carousel-control {
  display: none;
}
@media screen and (max-width: 999px) {
  .card--carousel {
    display: none;
  }
}
`;
 
const Sport = () => {
    return (
        <StyledSport>
            {/* <!-- SVG icon sprite --> */}
<svg xmlns="http://www.w3.org/2000/svg" style={{display: "none"}}>

  {/* <!-- Mail --> */}
  <symbol id="icon-email" viewBox="0 0 32 32">
    <title>envelope</title>
    <path fill="#F7BE00" d="M28.8 3.2c1.767 0 3.2 1.433 3.2 3.2v0 19.2c0 1.767-1.433 3.2-3.2 3.2v0h-25.6c-1.767 0-3.2-1.433-3.2-3.2v0-19.2c0-1.76 1.44-3.2 3.2-3.2h25.6zM21.808 17.76l10.192 7.84v-3.2l-8.192-6.24 8.192-6.56v-3.2l-16 12.8-16-12.8v3.2l8.192 6.56-8.192 6.24v3.2l10.192-7.84 5.808 4.64 5.808-4.64z"></path>
  </symbol>

  {/* <!-- Twitter --> */}
  <symbol id="icon-twitter" viewBox="0 0 30 24">
    <path fill="#F7BE00" d="M75.4838299,7.2085 C75.4965843,7.45825 75.5014898,7.71185714 75.5014898,7.96160714 C75.5014898,15.6604643 69.5412791,24.5347857 58.6421875,24.5347857 C55.2936773,24.5347857 52.1806323,23.5705 49.5581395,21.91675 C50.0231831,21.97075 50.4941134,21.9987143 50.9709302,21.9987143 C53.7494186,21.9987143 56.303234,21.0672143 58.3301962,19.507 C55.7381177,19.4587857 53.5482922,17.7770714 52.7948038,15.4627857 C53.1558503,15.5293214 53.527689,15.565 53.9093387,15.565 C54.4509084,15.565 54.9748183,15.4955714 55.4712573,15.3625 C52.7604651,14.8253929 50.7178052,12.4725357 50.7178052,9.64910714 L50.7178052,9.57678571 C51.5405879,10.0269099 52.4609339,10.2771871 53.4021076,10.30675 C51.8117369,9.2605 50.7648983,7.48042857 50.7648983,5.45735714 C50.7648983,4.39085714 51.0572674,3.38992857 51.568423,2.52882143 C54.4901526,6.05425 58.8580305,8.37142857 63.7831759,8.61539286 C63.6814331,8.17955661 63.6304154,7.73376762 63.6311047,7.28660714 C63.6311047,4.07167857 66.2820494,1.46521429 69.5550145,1.46521429 C71.2601744,1.46521429 72.7995276,2.17010714 73.881686,3.30314286 C75.2297238,3.04085714 76.4982922,2.55678571 77.6442224,1.8895 C77.2017442,3.24914286 76.2608648,4.39085714 75.0384084,5.11117857 C76.2373183,4.96942857 77.3773619,4.65892857 78.4418605,4.19510714 C77.6470894,5.36500249 76.6456022,6.38557198 75.484811,7.2085 L75.4838299,7.2085 Z"
          transform="translate(-49 -1)" />
  </symbol>

  {/* <!-- Facebook --> */}
  <symbol id="icon-facebook" viewBox="0 0 24 24">
    <path fill="#F7BE00" d="M20.00025,0 L3.99975,0 C1.791,0 0,1.791 0,4.0005 L0,19.9995 C0,22.209 1.79025,24 3.99975,24 L12,24 L12,13.5 L9,13.5 L9,10.5 L12,10.5 L12,8.25 C12,6.17925 13.67925,4.5 15.75,4.5 L19.5,4.5 L19.5,7.5 L15.75,7.5 C15.336,7.5 15,7.836 15,8.25 L15,10.5 L19.125,10.5 L18.375,13.5 L15,13.5 L15,24 L20.00025,24 C22.209,24 24,22.209 24,19.9995 L24,4.0005 C24,1.791 22.20975,0 20.00025,0 Z" />
  </symbol>

  {/* <!-- Twitch --> */}
  <symbol id="icon-twitch" viewBox="0 0 24 25">
    <path fill="#F7BE00" d="M2.57314142,0 L0.209603659,3.90625 L0.209603659,21.875 L6.51237101,21.875 L6.51237101,25 L9.66375469,25 L12.8151384,21.875 L16.754368,21.875 L23.8449812,14.84375 L23.8449812,0 L2.57314142,0 Z M20.6935976,13.28125 L16.754368,17.1875 L12.8151384,17.1875 L9.66375469,20.3125 L9.66375469,17.1875 L4.93667917,17.1875 L4.93667917,3.125 L20.6935976,3.125 L20.6935976,13.28125 Z M15.1786761,6.25 L17.5422139,6.25 L17.5422139,12.5 L15.1786761,12.5 L15.1786761,6.25 Z M10.4516006,6.25 L12.8151384,6.25 L12.8151384,12.5 L10.4516006,12.5 L10.4516006,6.25 Z" />
  </symbol>
</svg>

<header className="site-header" role="banner">
  {/* <!-- Main Menu --> */}
  <nav id="main-menu" className="navbar-collapse collapse">
    <ul className="nav navbar-nav navbar-right">
      {/* <li><a href="/login">Login</a></li>
      <li><a href="/register">Register</a></li> */}
      <li className="cta">
        <Link to="/camps" className="btn-outline-light">Request Early Access</Link>
      </li>
    </ul>
  </nav>
  <nav className="navbar">
    <div className="container-fluid">
      <div className="navbar-header">
        <Link className="navbar-brand" to="/">
         <img style={{margin:'2.3em 0 0 -1em',padding:'1em',width: '6em'}} src="https://dl3.pushbulletusercontent.com/KDqyUVJ5eWdugH1sqRvsPkNHSZxtviYo/dbprobadge-rmbg.png" alt="denard pro logo" />
          <span className="sr-only">Denard Bros</span>
        </Link>
        <div className="navbar-controls logged-out">
          <nav className="navbar-desktop">
            <ul className="nav" style={{marginLeft:'-0.32em'}}>
              <li><a href="/register"> <li className="has-tooltip">
            <a href="//twitter.com/TKpays">
                            <svg className="icon">
                              <use xlinkHref="#icon-twitter"></use>
                            </svg>
                            <span className="sr-only">Follow us on Twitter</span>
                            <span className="tooltip">Twitter</span>
                          </a>
          </li>
     </a></li>
              <li><a href="/login">     <li className="has-tooltip">
            <a href="//www.facebook.com/tkpays/">
                          <svg className="icon icon-fb">
                            <use xlinkHref="#icon-facebook"></use>
                          </svg>
                          <span className="sr-only">Find us on Facebook</span>
                          <span className="tooltip flush-r">Facebook</span>
                        </a>
          </li></a></li>
            </ul>
           
          </nav>
       

          <button type="button" className="navbar-toggle navbar-toggle-menu collapsed" data-toggle="collapse" data-target="#main-menu" aria-expanded="false">
                          <span className="sr-only">Toggle navigation</span>
                          <span className="icon-bar top"></span>
                          <span className="icon-bar mid"></span>
                          <span className="icon-bar bottom"></span>
                          </button>
        </div>
      </div>
    </div>
  </nav>

  <section className="hero">
    
    <div className="text">
      <div className="text-wrap">
        <h1 className="title">Learn from the pros.</h1>
        <p className="sbtitle">Take your game to the next level with our professional basketball pro camp and clinic.</p>
        <div className="actions">
          <Link to="/camps">Request Early Access</Link>
        </div>
      </div>
      
      {/* <!-- Latest Tournaments --> */}
      <div id="carousel-latest-tournaments" className="carousel card--carousel" data-ride="carousel">

      {/* <!-- Wrapper for slides --> */}
      <div className="carousel-inner" role="listbox">
        <div className="item active">
          
          <article className="card">
            <button className="toggle--close" type="button">Close</button>
              <figure className="card--media">
                <a href="#0"><img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/97621/img-16x9-FIFA19.jpg" alt=""/></a>
              </figure>
              <div className="card--content">
                <h2 className="card--title">Denard Bros Pro Run </h2>
                <p className="card--label">Week 1 Fashion Night</p>
                <p className="card--copy">Come experience the kickoff of the Summer Pro Run Series hosted by the Denard Bros.</p>
                <div className="card--actions">
                  <a href="#0" className="btn-primary-dark">Sign Up</a>
                </div>
              </div>
            </article>

          </div>
        <div className="item">
          <article className="card">
            <button className="toggle--close" type="button">Close</button>
            <figure className="card--media">
              <a href="#0"><img src="//s3-us-west-2.amazonaws.com/s.cdpn.io/97621/img-16x9-COD.jpg" alt=""/></a>
            </figure>
            <div className="card--content">
              <h2 className="card--title">Call of Duty</h2>
              <p className="card--label">Tournament Name</p>
              <p className="card--copy">Lorem ipsum, dolor sit amet consectetur adipisicing elit.</p>
              <div className="card--actions">
                <a href="#0" className="btn-primary-dark">Sign Up</a>
              </div>
            </div>
          </article>
        </div>
        
        <div className="item">
          <article className="card">
            <button className="toggle--close" type="button">Close</button>
            <figure className="card--media">
              <a href="#0"><img src="//s3-us-west-2.amazonaws.com/s.cdpn.io/97621/img-16x9-Fortnite.jpg" alt=""/></a>
            </figure>
         
          </article>
        </div>
        
        <div className="item">
          <article className="card">
            <button className="toggle--close" type="button">Close</button>
            <figure className="card--media">
              <a href="#0"><img src="//s3-us-west-2.amazonaws.com/s.cdpn.io/97621/img-16x9-DOTA.jpg" alt=""/></a>
            </figure>
           
          </article>
        </div>
        
        <div className="item">
          <article className="card">
            <button className="toggle--close" type="button">Close</button>
            <figure className="card--media">
              <a href="#0"><img src="//s3-us-west-2.amazonaws.com/s.cdpn.io/97621/img-16x9-League-of-Legends.jpg" alt=""/></a>
            </figure>
            <div className="card--content">
              <h2 className="card--title">League of Legends</h2>
              <p className="card--label">Tournament Name</p>
              <p className="card--copy">Lorem ipsum, dolor sit amet consectetur adipisicing elit.</p>
              <div className="card--actions">
                <a href="#0" className="btn-primary-dark">Sign Up</a>
              </div>
            </div>
          </article>
        </div>
      </div>

        {/* <!-- Controls --> */}
        <a className="left carousel-control" href="#carousel-latest-tournaments" role="button" data-slide="prev">
          <span className="glyphicon glyphicon-chevron-left" aria-hidden="true"></span>
          <span className="sr-only">Previous</span>
        </a>
        <a className="right carousel-control" href="#carousel-latest-tournaments" role="button" data-slide="next">
          <span className="glyphicon glyphicon-chevron-right" aria-hidden="true"></span>
          <span className="sr-only">Next</span>
        </a>
      </div>

    </div>
    
    <div className="media">
      <img className="hero-img img-overlay" src="https://dl3.pushbulletusercontent.com/4BN8NcTv0xTKjcRuuKotsxsb7KINm5l6/image.png" alt=""/>
    </div>

    <div className="footer-desktop">
      <p className="copyrights">© 2023 Denards Bros &nbsp; • &nbsp; <a href="/terms">Terms of Service</a> &nbsp; • &nbsp; <a href="mailto:info@tournamentkings.com?Subject=Hello%20again">Contact Us</a></p>
        <ul className="icon-list">
          {/* <!-- <li>
                        <a href="mailto:info@tournamentkings.com?Subject=Hello%20again">
                          <svg className="icon">
                            <use xlinkHref="#icon-email"></use>
                          </svg>
                          <span className="sr-only">Contact Us</span>
                        </a>
                      </li> --> */}
          <li className="has-tooltip">
            <a href="//twitter.com/TKpays">
                            <svg className="icon">
                              <use xlinkHref="#icon-twitter"></use>
                            </svg>
                            <span className="sr-only">Follow us on Twitter</span>
                            <span className="tooltip">Twitter</span>
                          </a>
          </li>
          <li className="has-tooltip">
            <a href="//www.facebook.com/tkpays/">
                          <svg className="icon icon-fb">
                            <use xlinkHref="#icon-facebook"></use>
                          </svg>
                          <span className="sr-only">Find us on Facebook</span>
                          <span className="tooltip flush-r">Facebook</span>
                        </a>
          </li>
        </ul>
    </div>
  </section>
</header>

<main id="main-content" role="main">

  <section className="callout">
    <div className="container">
      <h2 id="tournament-kings">
        <img className="logo" src="https://dl3.pushbulletusercontent.com/49vHkgXeCJTOuuyTmT8zuUpG6cDoC109/image.png" alt=""/>
        <span className="sr-only">Denard Bros</span>
      </h2>
      <p>Whether you're a beginner or a seasoned pro, our professional basketball pro camp and clinic is the perfect place to improve your game. Sign up today and start your journey to becoming a better player!</p>
      <a href="/register" className="btn-primary-dark">Register Now</a>
    </div>
  </section>

  <section className="info">
    <header>
      <h2 id="how-it-works">How it Works</h2>
      
    </header>

    <div className="steps">
      <article className="step">
        <h2 className="counter-item counter-item-one">Join a Camp</h2>
      </article>
      <article className="step">
        <h2 className="counter-item counter-item-two">Receive Pro Training</h2>
      </article>
      <article className="step">
        <h2 className="counter-item counter-item-three">Dominate the Game</h2>
      </article>
      <div className="actions">
        <a href="/register" className="btn-primary-dark">Go Pro</a>
      </div>
    </div>
  </section>

  <section className="join-beta theme-light">
    <div className="container">
        <img style={{position:'relative',left:'50%',transform:'translate3d(-50%,0,0)',width:'7em',aspectRatio:'1/1',border:'5px solid #000',margin:'-1em 0 2em 0',borderRadius:'50%'}} src="https://dl3.pushbulletusercontent.com/YEjwuHURwhFVpT5QPIJF0vjyM5ILvClX/348383015_6258970850846263_5407522851751983145_n.jpg" alt="" />
         <h2 id="join-beta-group">Denard Bros Pro Run</h2>
      <p>Led by the seasoned pro trainers Eddie Denard and his brother Aubery Denard exceptional Pro Trainer, this remarkable initiative is dedicated to elevating your skills to new heights. Prepare to be amazed by top-notch games from NBA players and global professionals. With a roster already boasting over 20 NBA players, the Pro Runs have exceeded all expectations, challenging the best in the summer basketball scene.</p> 
                             
                             <p>Don't miss out on this incredible opportunity to be a part of the transformative experience, connect with the Denard Bros Family, Professional Athletes, and the basketball community, the food is exceptional, and the value is unmatched.</p>
                             
                              <p>Plus, get ready to win exciting prizes, gift cards, and exclusive autographed gear. Join the Pro Runs, where only the pros reign supreme - no Joe Shmoes allowed. Don't wait, secure your spot and witness the thrilling journey firsthand.</p>
      {/* <h2 id="join-beta-group">Want to Join our Camp?</h2>
      <p>If you're serious about improving your basketball game, then our professional basketball pro camp and clinic is the perfect place for you. Sign up today and start your journey to becoming a better player!</p> */}
      <a href="https://www.eventbrite.com/e/denard-bros-pro-runs-the-warm-up-fashion-night-tickets-636145427097" className="btn-primary-dark">Get Tickets Now</a>
    </div>
  </section>

</main>
{/* 
<footer className="site-footer" role="contentinfo">
  <div className="container">
    <img className="logo-icon logo-icon-sm pb-2" src="https://dl3.pushbulletusercontent.com/49vHkgXeCJTOuuyTmT8zuUpG6cDoC109/image.png" alt="Denard Bros"/>
    <p><strong>Got something (meaningful) to say? Drop us a line.</strong></p>
    <a href="mailto:info@tournamentkings.com?Subject=Hello%20again" className="btn-outline-light">Contact Us</a>
    <ul className="icon-list">
      <li>
        <a href="//www.facebook.com//">
                    <svg className="icon">
                    <use xlinkHref="#icon-facebook"></use>
                    </svg>
                    <span className="sr-only">Find us on Facebook</span>
                </a>
      </li>
      <li>
        <a href="//twitter.com/">
                    <svg className="icon">
                    <use xlinkHref="#icon-twitter"></use>
                    </svg>
                    <span className="sr-only">Follow us on Twitter</span>
                </a>
      </li>
      {/* <!--<li>
                <a href="#0">
                    <svg className="icon">
                    <use xlinkHref="#icon-twitch"></use>
                    </svg>
                    <span className="sr-only">Watch us on Twitch</span>
                </a>
                </li>--> */}
    {/* </ul> */}
    {/* <p className="copyrights">Copyright © 2023 DenardBros All right reserved. <a href="/terms">Terms of Service</a>.
    </p>
  </div>
</footer> */}
 

{/* <!-- <a href="#0" className="btn-primary-outline">Primary Outline</a>
    <a href="#0" className="btn-primary-outline-dark">Primary Outline Dark</a>
    <a href="#0" className="btn-outline-light">Contact Us</a>
    <a href="#0" className="btn-outline-dark">Outline Dark</a> --> */}

        </StyledSport>
    )
}
 
export default Sport
 